const Hero = () => {
    return (
        <div className="flex flex-col items-center justify-center ">
            <div className="relative w-full h-[20dvh] md:h-[30vh] flex items-center justify-center">
                <h1
                    style={
                        {
                            letterSpacing: '0.05cqw',
                            textShadow: '0 4px 20px rgba(0,0,0,0.5)'
                        }
                    }
                    className=" text-[8cqw] lg:text-[6cqw] whitespace-nowrap font-bold text-center text-white z-10"
                >EMBRACE DEEP TIME
                </h1>
            </div>
        </div >
    )
}

export default Hero;