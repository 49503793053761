import Hero from '@/components/Hero';
import Overview from '@/components/Overview';
import type { MetaFunction } from "@remix-run/cloudflare";
import { motion } from 'framer-motion';

const IndexPage = () => {
  return (
    <main>
      <Hero />
      <motion.div
        transition={{
          duration: 0.2,
          delay: 0.05,
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className='flex flex-col gap-4 justify-center'
      >
        <div className='w-full flex flex-col gap-4 justify-center'>
          <Overview />
        </div>
      </motion.div>
    </main >
  );
};

export default IndexPage;

export const meta: MetaFunction = () => {
  return [
    { title: "Ephemera" },
    {
      name: "Ephemera",
      content: "Deep Time Corp presents the Ephemera Kit",
    },
    {
      name: "twitter:card",
      content: "summary_large_image"
    },
    {
      property: "og:image",
      content: "/ephemera-kit-overview.png",
    },
    {
      property: "og:title",
      content: "Ephemera Kit"
    },
    {
      property: "og:description",
      content: "Ephemera Kit by Inscribing Atlantis"
    },
    { property: "og:type", content: "website" },
    { property: "og:site_name", content: "Ephemera" },
    { property: "og:locale", content: "en_US" },
    {
      name: "twitter:image",
      content: "https://assets.ephemera.gallery/twitter-summary-large.png"
    }
  ];
};