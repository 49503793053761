import { usePlaceBidMutation } from '@/queries/account';
import { useCurrentAuctionQuery } from '@/queries/auction';
import { AuctionHeaderAndNav } from '@/routes/auction';
import { Link } from '@remix-run/react';
import { motion } from 'framer-motion';
import AuctionTableWithBidForm from './auctionTable/AuctionTableWithBidForm';
import { Logo } from './Logo';

const Overview = () => {
    const currentAuctionCycle = useCurrentAuctionQuery().data?.auction_cycle;
    const placeBidMutation = usePlaceBidMutation();
    return (
        <div className='flex flex-col gap-0 justify-center'>
            <div className='relative w-full min-h-[33vh] gap-8 flex flex-col items-center justify-center '>
                <Logo
                    size={120}
                    className='h-200 flex-shrink-0'
                />
                <div
                    style={{ lineHeight: '3.2rem' }}
                    className='text-xl pt-8 p-12 m-auto max-w-[800px] text-pretty'>
                    <b className='text-4xl'>EPHEMERA</b>, an archaeological project by
                    <span className='ml-4 underline decoration-neutral-800/20'>Inscribing Atlantis</span>,
                    inscribes planetary ephemeris data on satoshis (the smallest unit of Bitcoin)
                    from the time they were mined,
                    creating digital artifacts that connect astronomical time to decentralized
                    Bitcoin block time.
                    <Link to='/lore' className='text-blue-700 px-4 w-full text-xl font-medium p-2 '>Learn more...</Link>
                </div>
            </div>

            <motion.div
                className='m-auto w-full flex flex-col gap-2 my-8 max-w-[1400px] px-4'
                transition={{
                    duration: 0.2,
                    delay: 0.05,
                }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                {currentAuctionCycle &&
                    <AuctionHeaderAndNav auctionCycleCurrent={currentAuctionCycle} auctionCycleParam={currentAuctionCycle} />
                }
                <AuctionTableWithBidForm placeBidMutation={placeBidMutation} />
            </motion.div>

        </div>
    )
};


export default Overview;
